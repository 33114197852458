<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="600">
            <v-card>


              <v-toolbar dark>
                <v-card-title class="headline">
                  {{ `${edit ? 'Update' : 'Add'}` }}  driver
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="resetForm">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-select
                                    :items="users"
                                    item-value="id"
                                    item-text="full_name"
                                    v-model="vehicleDriver.user_id" outlined dense label= "Select driver">

                            </v-select>
                        </v-col>
<!--                        <v-col cols="6">-->
<!--                            <v-select-->
<!--                                    :items="vehicles"-->
<!--                                    item-value="id"-->
<!--                                    item-text="title"-->
<!--                                    v-model="vehicleDriver.vehicle_id" outlined dense label= "Select Bus/Vehicle ">-->

<!--                            </v-select>-->
<!--                        </v-col>-->

                        <v-col cols="12">
                            <div class="float-right">
                                <v-btn depressed @click="dialog = false;"  class="text-gray btn btn-standard cancel-btn">Cancel</v-btn>
                                <v-btn depressed :loading="isBusy" @click="createOrUpdate"  class="ml-2 text-white btn btn-primary">Save</v-btn>
                            </div>

                        </v-col>

                    </v-row>
                </v-card-text>

            </v-card>

        </v-dialog>

    </v-app>
</template>
<script>
    import {required} from "vuelidate/lib/validators";

    import UserService from "@/core/services/user/UserService";

    const userService = new UserService();
    import VehiclesService from "@/core/services/Transport/Vehicles/VehiclesService";
    const vehiclesService = new VehiclesService();



    import VehicleDriverService from "@/core/services/Transport/VehicleDriver/VehicleDriverService";
    const vehicleDriverService = new VehicleDriverService();
    export default {
        validations: {
            vehicleDriver: {
                user_id: {required},
                vehicle_id: {required},
            }
        },
        props:['vehicle'],
        data() {
            return {
                edit: false,
                dialog: false,
                sizeExceed: false,
                isBusy: false,
                users: [],
                vehicles: [],
                vehicleDriver: {
                    user_id: null,
                    vehicle_id: null,
                },
                search: {
                        type:'driver'
                }
            };
        },
        methods: {
            showModal(id = null) {
                if (id) {
                    this.edit = true
                    this.__get(id)
                }

                if (this.vehicle){
                    this.vehicleDriver.vehicle_id = this.vehicle.id;
                }
               this.dialog = true;
                this.__getVehicles();
                this.getUsers();

            },
            hideModal() {
                this.dialog = false;

            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            }, __get(id) {
                vehicleDriverService.show(id).then(response => {
                    this.vehicleDriver = response.data.vehicleDrivers
                })
            },
            __getVehicles() {
                vehiclesService
                    .paginate()
                    .then(response => {
                        this.vehicles = response.data.data;
                        this.page = response.data.meta.current_page;
                        this.total = response.data.meta.total;
                        this.perPage = response.data.meta.per_page;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },
            getUsers() {
                this.isBusy = true;
                userService.paginate(this.search).then(response => {
                    this.isBusy = false;
                    this.users = response.data.data;

                });
            },
            // generateFd() {
            //     // console.log(this.video)
            //     let fd = new FormData();
            //     for (let key in this.route) {
            //         if (key == "is_active" && this.video["is_active"]) {
            //             fd.append("is_active", this.video ? 1 : 0);
            //         } else {
            //             if (this.video[key] != null) {
            //                 fd.append(key, this.video[key]);
            //             }
            //         }
            //     }
            //     return fd;
            // },

            __getRoutes() {
                routesService
                    .paginate()
                    .then(response => {
                        this.routes = response.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },
            createOrUpdate() {
                this.$v.vehicleDriver.$touch();
                if (this.$v.vehicleDriver.$error) {
                    setTimeout(() => {
                        this.$v.vehicleDriver.$reset();
                    }, 3000);
                } else {
                    let fd = this.vehicleDriver;
                    if (this.edit) {
                        this.updateAlbum(fd);
                    } else {
                        this.createAlbum(fd);
                    }
                }
            },
            updateAlbum(fd) {
                this.isBusy = true;
                vehicleDriverService
                    .update(this.vehicleDriver.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");

                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },


            createAlbum(fd) {
                this.isBusy = true;
                vehicleDriverService
                    .store(fd)
                    .then(response => {
                        this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Information added");

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },
            resetForm() {
                this.$v.vehicleDriver.$reset();
                this.edit = false;
                this.route = {
                    title: null,
                    is_active: null
                };
                this.hideModal();
                this.$emit("refresh");
            }
        }
    };
</script>
